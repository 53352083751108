<template>
  <div class="main">
    <heads :inputvalue="searchkeyword"></heads>
    <banners></banners>
    <div class="content">
      <div class="search-title">搜索结果</div>
      <div class="search-subtitle">共找到“{{searchkeyword}}”相关期刊{{totals}}篇</div>
      <div class="search-ul">
        <div class="search-list">
          <div class="search-list-con" v-for="(item,key) in qikanlist" :key="key" @click="clicksearchqikan(item.Id)">
            <div class="search-list-con-img">
              <el-image
                style="width: 98px; height: 128px"
                :src="`${proxy.$imgurl}/qikanfmpic/${item.pycode}.jpg`"
                :fit="'fill'"
                :title="item.Title"
              ></el-image>
            </div>
            <div class="search-list-con-text">
              {{item.Title}}
            </div>
          </div>
        </div>
      </div>
      <div class="search-fy" v-if="qikanlist.length > 0">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totals"
          :current-page="currentPage"
          :default-page-size="pageSize"
          @current-change="fanye"
        />
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { get, post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { ref, reactive, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();

// 关键词
let searchkeyword=ref('')
searchkeyword.value=decodeURIComponent(router.currentRoute.value.query.keyword)
// 返回期刊列表
let qikanlist = reactive([]);
// 分页先关
let totals = ref(0);
let page = ref(1);
let currentPage = ref(1);
let pageSize = ref(20);

// 搜索
const search = () => {
  post("/Journal/GetQkByKeyWord", {
    keyword:searchkeyword.value,
    type: 0,
    page: page.value,
    psize:pageSize.value,
  }).then((res) => {
    // console.log(res);
    totals.value=res.total
    qikanlist.length=0
    qikanlist.push(...res.data)
  });
};
search();

// 跳转详情
const clicksearchqikan=(id)=>{
  // const url = router.resolve({
  //   path: "/xkzs/del",
  //   query: {
  //     id: id,
  //   },
  // });
  // window.open(url.href);
  window.open('//journal.yuanpingjia.com.cn/Journal/Details/'+id)
}

// 分页
const fanye = (val) => {
  page.value = val;
  currentPage.value=val
  search();
};
</script>

<style scoped>
.main {
  background: #fbfcff;
}
.content {
  width: 1200px;
  height: auto;
  padding: 40px 0;
  margin: auto;
}
.xtjs-title {
  font-size: 24px;
  text-align: center;
}
.xtjs-con {
  padding: 10px 0;
}
.xtjs-con p {
  text-indent: 32px;
  text-align: justify;
}

.search-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.search-subtitle {
  font-size: 14px;
  color: #999;
  padding-left: 10px;
  margin: 10px 0 0 0;
}
.search-list:after {
  content: "";
  display: table;
  clear: both;
}
.search-list-con {
  width: 100px;
  height: 150px;
  float: left;
  margin: 10px 10px 0 10px;
}
.search-list-con-img {
  width: 98px;
  height: 128px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.search-list-con-text {
  width: 100%;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
}
.search-fy{
  margin: 10px 0 0 10px;
}
</style>
